#hero {
  display: flex;
  flex-direction: column;
  // margin-bottom: 20px;
  position: relative;

  .slider {
    height: 950px;
    background-color: $secondary-color;

    a.previousButton {
      display: none;
    }

    a.nextButton {
      display: none;
    }
  }
  .hero-wrapper {
    width: 100%;
    height: auto;
    position: relative;

    .hero-img {
      max-width: 100%;
      height: auto;
    }
  }

  form {
    position: absolute;
    display: flex;
    width: 45%;
    height: 50px;
    bottom: 320px;
    left: 20px;
    justify-content: flex-start;
    align-items: center;
    background: $primary-bg;


    .label {
      color: $ternary-font-color;
      font-size: 0.9rem;
      font-weight: bolder;
      padding: 0 10px;
    }

    input {
      border: none;
      width: 40%;
      padding: 0 5px;
      height: 30px;
      font-size: 1rem;
      color: $ternary-font-color;
      text-transform: uppercase;
    }

    input:focus {
      border-bottom: 2px solid $secondary-color;
    }

    button {
      border: none;
      background: white;
      height: 50px;
      padding: 5px 15px;
      color: $secondary-color;
      font-weight: bolder;
      font-size: 1rem;
    }
  }

  .hero-footer {
    background: $secondary-color;
    color: $primary-bg;
    padding: 10px 20px;

    h1 {
      font-size: 2.7em;
      line-height: 1.1;
      font-weight: 900;
      color: #ffffff;
      margin: 0 0 15px;
      padding: 0;
      text-transform: uppercase;
    }

    p {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 1240px) {
  #hero {
    .slider {
      height: 800px;
    }
  }
}
@media screen and (max-width: 1080px) {
  #hero {
    .slider {
      height: 750px;
    }
  }
}
@media screen and (max-width: 992px) {
  #hero {
    .slider {
      height: 650px;
    }
    .hero-footer {
      h1 {
        font-size: 2.1rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #hero {
    .slider {
      height: 460px;
      .hero-footer {
        height: 25%;
        h1 {
          font-size: 1.4rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
    form {
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);

      .postcode {
        flex: 1;
        font-size: 0.8rem;
      }
      .submit {
      }
      :after {
        right: 6px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  #hero {
    form {
      height: 80px;

      .label {
        position: absolute;
        left: 0;
        top: 16px;
      }
      .postcode {
        position: absolute;
        font-size: 0.8rem;
        left: 5px;
        top: 35px;
        opacity: 0.6;
      }
      .submit {
        position: absolute;
        right: 10px;
        border: 1px solid #10106a;
        padding: 0 15px;
        font-size: 0.8rem;
        height: 31px;
        font-weight: 900;
        box-shadow: 1px 1px 2px rgba(16, 16, 106, 0.6);
      }
      :after {
        // content: '';
        background-image: none;
      }
    }
  }
}

// @media screen and (max-width: 420px) {
//   #hero {
//     form {
//       height: 80px;

//       .label {
//         position: absolute;
//         left: 0px;
//         top: 16px;
//       }
//       .postcode {
//         position: absolute;
//         font-size: 0.8rem;
//         left: 5px;
//         top: 35px;
//         opacity: 0.6;
//       }
//       .submit {
//         position: absolute;
//         right: -60px;
//         border: 1px solid #10106a;
//         padding: 0px 15px;
//         font-size: 0.8rem;
//         height: 31px;
//         font-weight: 900;
//         box-shadow: 1px 1px 2px rgba(16, 16, 106, 0.6);
//       }
//       :after {
//         // content: '';
//         background-image: none;
//       }
//     }
//   }
// }
