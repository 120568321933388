#account {
  display: flex;

  .header {
    border-bottom: 1px solid $gray;
    padding: 5px 0;
    h3 {
      color: $secondary-color;
      font-size: 1rem;
      font-weight: 900;
    }
  }

  .body {
    padding: 10px 0;
  }

  .separator {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    hr {
      color: $gray;
      width: 450px;
      padding: 0 4px;
      margin: 10px 0;
    }

    p{
      width: 400px;
      color: $gray;
      margin: -5px 6px 10px 0px;
      padding: 0 4px;
      font-size: 0.8rem !important;
    }
  }
  #login {
    input {
      width: 100%;
    }
    flex: 2;
    margin: 20px;
    .card {
      background: $cloud;
      padding: 15px;
      .body {
        p {
          font-size: 1rem;
        }

      }

      .footer {
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        a {
          font-size: 0.8rem;
          font-weight: 600;
          margin-right: 10px;
        }
        button {
          background: $secondary-color;
          border: none;
          color: $primary-font-color;
          height: 50px;
          width: 80px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }

  #register {
    flex: 3;
    margin: 20px;

    input {
      width: 100%;
    }
    .telephone-input {
      //width: 50%;
    }
    .card {
      padding: 15px;
      .header {
        h3 {

        }
      }

      .footer {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-size: 0.8rem;
        }
        button {
          background: $secondary-color;
          border: none;
          color: $primary-font-color;
          height: 50px;
          width: 160px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}


@media screen and (max-width: 955px) {
  #account {
    flex-direction: column;
  }

  #account #register input {
    width: 50%;
  }
}


@media screen and (max-width: 500px) {
  #account {
    flex-direction: column;

    .separator {
      p {
        margin: 0 0 10px 0;
        padding: 0;
      }
    }
    #login {
      margin: 10px;

      input {
        width: 60%;
      }
    }
    #register {
      margin: 0;

      .card {
        padding: 15px 5px;
      }
      input {
        width: 50%;
        height: 30px;
      }
    }
  }
}
