#find-store {
  display: flex;
  padding-bottom: 30px;

  #stores {
    display: flex;
    flex-direction: column;
    flex: 4;
    padding: 10px 25px;


    .not-found {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 900;
      text-align: center;
      margin-top: 30px;
    }

    .title {
      font-size: 2.6rem;
      font-weight: 900;
      color: $secondary-color;
      margin: 10px 0;
    }

    form {
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      background: #d23013;
      background: linear-gradient(to right, $primary-dark-color 0%, $primary-lighter-color 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4b0e03", endColorstr="#d61a0c", GradientType=1);

      p {
        margin: 10px 0;
        color: $primary-font-color;
        font-size: 1.1rem;
        font-weight: 500;
      }

      .form-group {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 10px;

        input {
          background: transparent;
          border: none;
          border-bottom: 3px solid $primary-lighter-color;
          height: 35px;
          flex: 1;
          color: white;
          font-size: 1rem;
          text-transform: uppercase;
        }

        button {
          background: $secondary-dark-color;
          width: 130px;
          border: none;
          color: white;
          height: 40px;
          margin-left: 20px;
          font-size: 1rem;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }

    .nearest-title {
      color: $secondary-color;
      font-weight: 900;
      margin: 15px 0 5px 0;
    }

    .store {
      border: 1px solid $gray;
      box-shadow: 0 2px 3px #ccc;

      .header {
        display: flex;
        border-bottom: 1px solid $gray;

        .info {
          width: 65%;
          padding: 10px 6px;

          h1 {
            color: $primary-color;
            display: inline-block;
            margin: 0;
            font-weight: 900;
            margin-right: 5px;
          }

          span {
            font-weight: 400;
            font-size: 0.8rem;
          }

          p {
            margin: 4px 0;
          }
        }

        .action {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;

          a {
            background: $secondary-color;
            color: $primary-font-color;
            height: 50px;
            border: none;
            width: 95%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.85rem;
            font-weight: 700;
          }
        }
      }

      .status {
        display: flex;
        border-bottom: 1px solid $gray;
        justify-content: space-evenly;

        .phone {
        }

        .delivery {
        }

        .collection {
        }

        p {
          margin: 0;
        }

        .label {
          display: inline-block;
          font-size: 0.7rem;
        }

        span {
          display: inline-block;
          font-size: 0.6rem;
          text-transform: uppercase;
          margin-left: 5px;
          background: $primary-color;
          color: $primary-font-color;
          padding: 3px 5px 3px 5px;
          border-radius: 2px;
        }

        .online {
          background: $success;
        }

        .offline {
          background: $error;
        }

        .value {
          margin: 0;
          font-size: 1.4rem;
          font-weight: 700;
          color: $secondary-bg;
        }
      }

      .social {
        padding: 10px 15px;

        button {
          background: #45619d;
          border: none;
          color: $primary-font-color;
          font-size: 1.14rem;
          padding: 10px 15px;

          i {
            font-size: 1rem;
          }
        }
      }
      .operations {
        padding: 15px;
        font-size: 0.875rem;
        .head {
          display: flex;
          margin-bottom: 8px;
          .o-instore {
            color: $secondary-color;
            font-weight: 600;
          }
        
          .o-delivery {
            color: $secondary-color;
            font-weight: 600;
          }
        }
        .content {
          display: flex;
          flex-direction: column;

          .o-row {
            display: flex;
            border-bottom: 1px solid #e5e5e5;
            padding: 5px 0;
            span {
              display: block;
            }
          }
        }
        .o-day {
          width: 40%;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          p{
            margin-bottom: 8px;
          }
        }
        .o-time {
          margin-bottom: 8px;
        }
        .o-instore {
          width: 30%;
          display: flex;
          align-items: center;
        }
        .o-delivery {
          width: 30%;
          display: block;
          align-items: center;
        }
      }
    }

    .others-title {
      color: $secondary-color;
      font-size: 1.1rem;
      margin: 25px 0 0 0;
      font-weight: 900;
    }

    .note {
      color: $secondary-color;
      font-size: 0.75rem;
      margin-bottom: 15px;
    }
  }

  #map {
    display: flex;
    flex: 3 1;
    padding: 77px 15px 0 15px;
    height: 600px;

    .marker-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .info-window {
        background: $cloud;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        min-width: 200px;
        padding: 10px;
        bottom: 46px;

        h3 {
          color: $secondary-color;
          font-weight: 800;
          text-transform: uppercase;
        }
        a {
          color: $primary-color;
          font-weight: 800;
        }
      }
    }

    .show::after {
      background: $cloud;
      box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
      content: '';
      height: 15px;
      position: absolute;
      top: -11px;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 15px;
    }
  }
}

@media screen and (max-width: 760px) {
  #find-store {
    flex-direction: column;

    #stores {
      padding: 10px 5px;
    }
  }
}

@media screen and (max-width: 540px) {
  #find-store {
    #stores {
      padding: 10px 5px;

      form {
        padding: 10px 25px;
        .form-group {
          flex-direction: column;
          align-items: center;
        }

        input {
          width: 100%;
        }

        button {
          background: $secondary-color !important;
          width: 100% !important;
          margin-top: 5px;
        }
      }
      .store {
        .header {
          flex-direction: column;
          padding: 5px 0;

          .info {
            width: 95%;
          }
        }
      }
    }

    .status {
      justify-content: center;
      flex-direction: column;
      align-items: center;

      div {
        border-bottom: 1px solid $gray;
        padding: 3px 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}
