#jobs {

  .hero {
    background: #d23013;
    background: linear-gradient(to bottom, #d23013 0%, #4b0e03 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d61a0c", endColorstr="#4b0e03",GradientType=0 );
    margin-top: 30px;
    padding: 25px 20px;
    color: white;
    position: relative;

    p.note {
      width: 50%;
    }

    form {
      width: 50%;
      background: linear-gradient(to bottom, #d23013 0%, #4b0e03 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d61a0c", endColorstr="#4b0e03",GradientType=0 );
      padding: 20px 15px;
      box-sizing: border-box;

      .label {
        color: $primary-font-color;
        font-size: 1.2rem;
        font-weight: 500;
      }

      .form-group {
        width: 100%;
        input {
          background: transparent;
          border: none;
          border-bottom: 2px solid $primary-lighter-color;
          color: $primary-font-color;
          width: 100% !important;
        }
        button {
          background: $primary-dark-color;
          color: $primary-font-color;
          border: none;
          width: 200px;
          height: 50px;
          margin-left: 10px;
          font-weight: 600;
          font-size: 1rem;
          cursor: pointer;
        }

      }
    }
    h1 {
      font-size: 3.5rem;
      font-weight: 900;
      width: 70%;
    }
    p {
      margin: 10px 0;
      font-weight: 500;
    }
  }

  .hero::after {
    content: "";
    display: block;
    position: absolute;
    right: 20px;
    bottom: 0;
    top: -20px;
    width: 590px;
    background-image: url('../images/join-team.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
  }
  .info {
    padding: 20px;

    h1 {
      color: $secondary-color;
      font-size: 2rem;
      font-weight: 900;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 20px;
      font-weight: 600;
    }
  }
  .column {
    flex-direction: column;
  }

  form {
    align-items: flex-start;
    margin-bottom: 30px;

    .form-group {
      width: 85%;

      p {
        width: 30%;
      }
      input[type='text'] {
        width: 70%;
      }

      .radio {
        width: 60%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }

      input[type='radio'] {
        width: 18px;
      }

      textarea {
        width: 70%;
        border: 1px solid $gray;
        font-size: 1.3rem;
      }

    }

    .footer {
      display: flex;
      justify-content: space-between;
      width: 85%;
      margin-top: 25px;
      p {
        font-size: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .text-primary {
          font-size: 2rem;
          line-height: 0px;
        }
      }
      button {
        background: $secondary-color;
        color: $primary-font-color;
        font-size: 1rem;
        border: none;
        padding: 15px;
      }
    }
  }
}



@media screen and (max-width: 1000px) {
  #jobs {
    padding: 5px;

    .hero {
      form {
        width: 100%;
      }
    }

    .hero::after {
      display: none
    }
  }

  #apps {
    padding: 0;
    flex-direction: column;

    .column {
      padding: 10px 0;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 760px) {

  #jobs {
    padding: 5px;

    .row {
      flex-direction: column;
    }
    .hero {
      h1 {
        font-size: 1.5rem;
      }
    }


    form {
      align-items: center;
      .form-group {
        width: 100%;
        .radio {
          width: 88%;
        }
      }


      .recaptcha {
        margin-top: 10px;
        width: 85%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }


    .download {
      flex-direction: column;

      .img {
        width: 60%;
        margin: 10px 0;
      }
    }
  }
}
