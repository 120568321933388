#about {
  .row {
    display: flex;
    padding: 0 20px 0 20px;
    box-sizing: border-box;

    h1 {
      color: $secondary-color;
      font-weight: 900;
      font-size: 2.45rem;
      margin: 15px 0;
    }
    .column {
      flex-direction: column;

      h5 {
        font-size: 1.7rem;
        padding-right: 20px;
        font-weight: 500;
        padding: 15px 15px 15px 0px;
      }

      h4 {
        color: $secondary-color;
        font-size: 2.25rem;
        margin: 5px 0;
        font-weight: 900;
      }

      p {
        font-size: 1rem;
        font-weight: 600;
        padding: 10px 0;
      }

      img {
        width: auto;
      }
    }
  }

  .faqs {
    background: $secondary-color;
    color: $primary-font-color;
    padding-bottom: 20px;

    h1 {
      color: $primary-font-color;
    }
  }
}
@media screen and (max-width: 1240px) {
  #about {
    .our-story {
      flex: 1 1 25%;
    }
    .our-story-desc {
      flex: 1 1 65%;
    }
  }
}

@media screen and (max-width: 768px) {
  #about {
    .row {
      h1 {
        margin: 15px 0 -4px;
      }
    }
    .content-row {
      flex-direction: column;
      .our-story {
        h5 {
          font-size: 1.3rem;
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .our-story-desc {
        h4 {
          font-size: 1.9rem;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
    .faqs {
      .column {
        h1 {
          font-size: 1.8rem;
          margin-bottom: 6px;
        }
        p {
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
    }
  }
}


@media screen and (max-width: 760px) {
  #about {
    padding: 5px;

    .row {
      flex-direction: column;
    }

    .items {
      justify-content: center;

      .item {
        .thumb {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  #about {
    padding: 5px;

    .content-row {
      flex-direction: column;
    }

    .items {
      justify-content: center;

      .item {
        .thumb {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
