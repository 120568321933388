#sitemap {
  padding: 25px;

  .column {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
  }

  h1 {
    color: $secondary-color;
    font-size: 3rem;
    font-weight: 900;
  }

  h2 {
    color: $secondary-color;
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.05rem;
    margin-bottom: 25px;

    strong {
      font-weight: 600;
    }
  }

  ul {
    margin-top: 0;

    li {
      font-size: 1rem;
      color: $coal;
      margin-bottom: 5px;
    }
  }
}


@media screen and (max-width: 760px) {
  #sitemap {
    padding: 5px;

    .row {
      flex-direction: column;
      .column {
        padding: 0;
        justify-content: center;

        h1 {
          font-size: 2rem;
          margin-bottom: 5px;
        }

        p {
          text-align: justify;
        }
      }
    }

    ul {
      padding-left: 20px;
    }
  }
}
