#shopping-cart {
  padding: 15px;

  .row {
    display: flex;
    flex: 1;

    h3 {
      color: $secondary-color;
      font-size: 3rem;
      margin: 10px 10px 0 10px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 0 10px 0 10px;
  }

  .f1 {
    flex: 1;
  }

  .f2 {
    flex: 2;
  }

  .note {
    padding: 15px 0;
    border-bottom: 1px solid $cloud;
    font-size: 1.05rem;

    h2 {
      color: $secondary-color;
      font-size: 1.4rem;
      margin-bottom: 5px;
    }

    .primary {
      color: $primary-color;
      font-weight: 700;
    }
  }

  .cart-details {
    border: 1px solid $cloud;
    min-height: 425px;

    .c-head {
      display: flex;

      div {
        border-right: 1px solid $cloud;
        border-bottom: 1px solid $cloud;
        color: $secondary-color;
        flex: 1;
        text-align: center;
        font-weight: bold;
        padding: 10px 0;
      }

      .c-item {
        flex: 3;
      }

      .c-price {

      }

      .c-quantity {
      }

      .c-subtotal {

      }
    }

    .c-body {
      display: flex;
      border-top: 2px solid $gray;
      flex-direction: column;

      .alert-danger {
        margin: 10px;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
      }

      div {
        flex: 1;
        padding: 5px 0;
      }

      .c-item {
        flex: 3;
        text-align: left;
        display: flex;
        padding: 0 5px;

        .remove {
          color: $primary-color;
          cursor: pointer;
          flex: 1;

          i {

          }
        }

        .content {
          flex: 12 1;
          text-align: left;
          padding: 5px 2px;

          h2 {
            color: $secondary-color;
            font-size: 1.1rem;
          }

          p {
            font-size: 0.85rem;
          }

          ul {
            margin: 0;
          }
        }
      }

      .c-price {

      }

      .c-quantity {
        display: flex;

        .increment {
          background: $success;
          color: $primary-font-color;
          border: none;
          width: 30px;
          height: 29px;
          cursor: pointer;
        }
        .decrement {
          background: $error;
          color: $primary-font-color;
          border: none;
          width: 30px;
          height: 29px;
          cursor: pointer;
        }
        input {
          border: 1px solid #aaaaaa;
          width: 18px;
          height: 21px;
          padding: 3px 5px;
          font-size: 1rem;
          font-weight: 600;
          border-radius: 0px;
        }
      }

      .c-subtotal {

      }

      .item-row {
        border-bottom: 1px solid $cloud;
      }
    }

    .c-footer {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      padding: 5px 0 0 0;

      .row {
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 0 15px;
      }

      .label {
        color: $secondary-color;
        width: 100px;
        text-align: center;
        font-weight: 800;
        font-size: 1rem;
        padding: 5px 15px;
        display: flex;
        flex: 1 1;
        align-items: center;
        justify-content: flex-end;
      }

      .value {
        color: $coal;
        font-size: 1rem;
        font-weight: 600;
        width: 65px;
      }

      .total {
        color: $primary-color;
        font-size: 1.2rem;
        font-weight: 700;
        width: 68px;
      }
    }
  }

  .f-end {
    justify-content: flex-end;
  }

  .disabled {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    .alert-danger {
      padding: 5px 6px;
      margin: 5px 0;
    }

    button {
      font-size: 1rem;
      padding: 15px;
      background: $coal;
      color: $primary-font-color;
      margin-top: 0;
      border: none;
    }
  }

  .cart-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .cart-checkout-mode {
      width: 69%;
      font-size: 1.1rem;
      font-weight: 800;

      .checkout-mode {
        padding: 0 0 0 3px;
        .mode {
          height: 40px;
        }
        p {
          font-size: 1rem;
        }
      }
    }

    .voucher {
      background: $secondary-color;
      color: $primary-font-color;
      display: flex;
      padding: 25px 15px;
      margin-top: 10px;
      width: 65%;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      font-size: 1.1rem;
      font-weight: 800;
      height: 50px;

      .label {
        padding: 0 15px;
      }

      input {
        background: $secondary-dark-color;
        border: none;
        height: 35px;
        width: 100%;
        color: white;
        font-size: 1.2rem;
        padding: 0 5px;
      }

      .action {
        background: $secondary-color;
        color: $primary-font-color;
        padding: 0 15px;
        border: none;
        font-size: 0.9rem;
        font-weight: 600;
        cursor: pointer;
        width: 40%;
      }

      .action:hover {
        color: $primary-lighter-color;
      }

    }
  }

  .row {
    .f-end {
      flex-direction: row;
    }

    .continue {
      font-size: 1rem;
      color: $coal;
      padding: 15px;
      margin-top: 10px;
    }

    .update {
      font-size: 1rem;
      padding: 15px;
      background: $coal;
      color: $primary-font-color;
      margin-top: 10px;
    }

    .checkout {
      font-size: 1.2rem;
      padding: 15px;
      background: $primary-color;
      color: $primary-font-color;
      margin-top: 10px;
      margin-left: 10px;
      width: 87%;
      text-align: center;

      h4 {
        font-weight: 900;
      }
    }

  }
}

@media screen and (max-width: 1135px) {
  .voucher {
    width: 100% !important;
  }
}

@media screen and (max-width: 757px) {
  #shopping-cart {
    .cart-note {
      display: none !important;
    }

    .cart-details {
      min-height: auto;
    }
  }
}

@media screen and (max-width: 540px) {
  #shopping-cart {
    padding: 5px;

    .column {
      padding: 0;
    }

    .row {
      .continue {
        font-size: 0.8rem;
        padding: 15px 0;
        margin-top: 10px;
        text-align: center;
      }

      .checkout {
        font-size: 0.8rem;
        padding: 15px 5px;
        margin-top: 10px;
        text-align: center;
      }
    }

    .voucher {
      .label {
        padding: 0 7px 0 0;
      }

      .action {
        padding: 0 0 0 7px;
      }
    }
  }
}

