#find-item {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;

  .head {
    padding: 15px 0 0 0;
    font-size: 1.4rem;

    h3 {
      color: $secondary-color;
      font-weight: 900;
    }
  }

  .menu {
    width: 95%;
    display: flex;
    justify-content: center;

    ul {
      list-style: none;
      margin: 25px 0;
      padding: 0;

      li {
        margin: 5px 0;

        h2 {
          font-size: 0.951rem;
          padding: 7px 12px;
          font-weight: 400;
          color: $ternary-font-color;
        }
      }

      .active {
        border-left: 2px solid $primary-color;

        h2 {
          font-weight: bold;
        }
      }
    }
  }

  .items {
    width: 95%;
    margin: 20px 0;
    border-left: 3px solid $gray;
    padding-right: 25px;

    .store {
      background: $cloud;

      .info {
        padding: 15px 0;
        margin: 0 15px;
        border-bottom: 1px solid $gray;

        .title {
          h1 {
            color: $secondary-color;
            display: inline-block;
            font-size: 1rem;
            font-weight: 900;
          }

          span {
            color: $primary-color;
            font-size: 0.65rem;
            padding-left: 3px;
            font-weight: 600;
          }
        }

        .address {
          padding: 5px 0;
        }
      }

      .operations {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 15px;

        .operation {
          flex: 1;
        }

        h3 {
          font-size: 1rem;
          font-weight: 600;
        }

        p {
          font-size: 0.9rem;
          font-weight: 400;
        }
      }

    }

    .menu-items {
      padding: 15px 15px;

      .header {
        padding: 5px 0;

        h2 {
          color: $primary-color;
          font-size: 1.7rem;
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      .body {
        .item {
          display: flex;
          margin: 5px 0 10px 0;
          border-top: 1px solid $gray;
          padding: 5px 0;

          .thumb {
            padding: 0px;
            justify-content: center;
            align-items: center;
            display: flex;

            img {
              width: 70px;
              height: 70px;
            }
          }

          .info {
            flex: 4;
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;

            .title {
              color: $secondary-color;
              font-size: 1rem;
              font-weight: 600;
              padding: 3px 0;
            }

            .description {
              font-size: 0.8rem;
              line-height: 0.8rem;
            }

            .price {
              font-weight: 600;
            }
          }

          .action {
            a {
              display: flex;
              justify-content: space-around;
              align-items: center;
              width: 70px;
            }
          }

          .action {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              color: $coal;
              background: transparent;
              border: none;
              width: 70px;

              i {
                color: $coal;
                margin-right: 4px;
              }

              span {
                color: $coal;
              }
            }
          }
        }
      }
    }
  }

  .cart {
    width: 90%;
    border: 1px solid #aaaaaa;
    margin-top: 25px;
    border-radius: 3px;
    padding: 10px 10px;
    max-height: 350px;
    height: fit-content;

    .header {
      border-bottom: 1px solid $gray;
      margin: 0;
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      padding-bottom: 3px;
      align-items: flex-end;

      h2 {
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
      }

      i {
        display: inline-block;
        font-size: 1.4rem;
        color: $secondary-color;
        margin-right: 10px;
      }
    }

    .body {
      max-height: 150px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .alert {
        margin: 10px;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
      }

    }

    .body::-webkit-scrollbar {
      display: none;
    }

    .cart-item {
      display: flex;
      border-bottom: 1px solid $gray;
      padding: 5px 0;

      .remove {
        flex: 1;

        i {
          color: $primary-color;
          cursor: pointer;
        }
      }

      .cart-body {
        flex: 10;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          p {
            padding: 0 3px;
            font-size: 1rem;
            font-weight: 600;
            width: 10%;
          }

          h2 {
            font-size: 0.85rem;
            font-weight: 500;
            width: 69%;
          }
        }

        .tier {
          display: flex;
          padding: 1px 0;

          .tier-title {
            font-size: 0.8rem;
            font-weight: 700;
            padding-right: 8px;
          }

          .tier-value {
            font-size: 0.8rem;
            padding: 0 0;
            width: 100%;
          }

          ul {
            list-style: none;
            margin: 0;
            li {
              font-size: 0.65rem;
            }
          }
        }

      }

      .price {
        color: $secondary-color;
        padding: 0 5px;
        font-size: 0.75rem;
        font-weight: 800;
        width: 21%;

        p {

        }
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px 0 0;

      .label {
        font-weight: 400;
      }

      p {
        font-weight: 800;
      }
    }

    .checkout-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      background: $secondary-color;
      margin: 5px 10px 0 10px;
      font-size: 1rem;
      font-weight: 600;
      color: white;

      span {
        border: none;
        font-size: 1rem;
      }
    }

  }
}


@media screen and (max-width: 980px) {
    #find-item {
        padding: 0 5px;

        .cart {
            display: none;
          }

        .row {
            display: block;

            .w-75 {
                width: 100% !important;
              }
          }
        .items {
            width: 100%;
            box-sizing: border-box;
            padding: 0;
            margin: 0;
          }
      }
  }
@media screen and (max-width: 980px) {
  #find-item {
    .items {
      .menu-items {
        padding: 5px;

        .header {
          h2 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1100px) {
  #shop {
    .items {
      .operations {
        font-size: 1rem;

        .operation {
          text-align: center;
        }

        h3 {
          font-size: 0.8rem;
        }

        p {
          font-size: 0.8rem;
        }
      }

      .menu-items .body .item {
        height: auto;
      }
    }
  }
}
