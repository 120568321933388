#profile {
  padding: 25px;
  display: flex;

  .profile-summary {
    .summary {
      border: 1px solid $gray;

      .header {
        background: $cloud;
        padding: 15px;
        border-bottom: 1px solid $gray;

        h2 {
          color: $secondary-color;
          font-size: 1rem;
          text-transform: uppercase;
          margin-bottom: 3px;
          font-weight: 800;
        }

        p {
          color: $coal;
          font-size: 0.825rem;
          margin-bottom: 3px;
        }
      }

      .body {
        a {
          border-bottom: 1px solid $gray;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px;
          font-size: 0.95rem;
        }

        .active {
          font-weight: 900;
          color: $secondary-color !important;
        }
      }
    }
  }

  .remove {
    background: $primary-bg;
    color: $coal;
    border: none;
    font-size: 0.8rem;
    padding: 7px;
    font-weight: 800;
    cursor: pointer;
  }

  .account {
    padding: 15px;
    position: relative;

    .address-suggestions {
      display: flex;
      flex-wrap: wrap;
      height: 215px;
      overflow-y: scroll;
      background: $cloud;
      padding: 0 5px;

      .center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }

      .selected {
        border: 2px solid $primary-color !important;
      }

      h2 {
        color: $secondary-color;
        font-size: 1rem;
        font-weight: 900;
        margin: 10px 0;
        width: 100%;
      }
      .address-suggestion {
        width: 100%;
        border: 1px solid $gray;
        margin: 5px;
        .address {
          h5 {
            font-size: 1.2rem;
            padding: 5px;
          }
        }
        .other {
          font-size: 01rem;
          color: $ternary-font-color;
          padding: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .other-info {
            margin-left: 20px;
          }
        }
      }
    }

    .w-73 {
      width: 84% !important;
    }

    .w-80 {
      width: 96.5% !important;
    }

    form {
      .form-group {
        width: 100%;
      }


      button {
        color: $primary-font-color;
        font-weight: 700;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 15px 0;
        width: 25%;
        cursor: pointer;
        font-size: 1rem;
        border: none;
        height: 53px;
      }

      .secondary {
        background: $secondary-color;
      }
    }

    .preferences {

      h3 {
        font-weight: 400;
        font-size: 150%;
        margin-top: 11px;
      }

      .form-group {
        justify-content: flex-start;
        margin: 10px 0;

        input {
          font-size: 1rem;
          width: 30px;
          margin-right: 15px;

        }
      }

      .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;

        button {
          color: $primary-font-color;
          font-weight: 700;
          justify-content: center;
          align-items: center;
          display: flex;
          margin: 15px 0;
          width: 44%;
          cursor: pointer;
          font-size: 1.2rem;
          border: none;
          height: 60px;
        }

        .light {
          background: $cloud;
          color: $coal;
        }

        .secondary {
          background: $secondary-color;
        }
      }
    }

    .vouchers {
      width: 100%;
      margin-top: 20px;
      border-collapse: collapse;

      th {
        text-align: left;
        padding: 0 7px 5px;
        border-bottom: 2px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
        font-weight: 600;
        border-bottom-left-radius: 6px;
      }

      .voucher {
        border-bottom: 1px solid #d9d9d9;

        td {
          text-align: left;
          padding: 5px 7px 5px;
          height: 40px;
          text-transform: capitalize;
        }

        .btn-cell {
          text-align: center;

          button {
            background: $secondary-color;
            color: $primary-font-color;
            border: none;
            font-size: 1rem;
            font-weight: 700;
            width: 100px;
            padding: 10px 0;
            cursor: pointer;
          }
        }
      }
    }

    .new {
      color: $coal;
      position: absolute;
      right: 16px;
      top: 35px;
      font-size: 0.85rem;
      font-weight: 700;
    }

    form {
      .form-group {
        input {
          width: 60%;
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        padding: 15px 0;

        button {
          border: none;
          padding: 15px;
          font-size: 1rem;
          font-weight: 700;
          cursor: pointer;
        }

        .light {
          background: $primary-bg;
          color: $coal;
        }

        .secondary {
          background: $secondary-color;
          color: $primary-font-color;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 200px;
        }
      }
    }

    h1 {
      color: $secondary-color;
      font-size: 2.5rem;
      font-weight: 900;
      display: flex;
      border-bottom: 2px solid $secondary-color;

    }

    .header {
      padding: 10px 0;

      .row {
        display: flex;
        justify-content: space-between;
        width: 40%;
        align-items: center;

        .label {
          h2 {
            font-weight: 600;
            font-size: 1.15rem;
            text-transform: capitalize;
            margin: 5px 0;
            color: $coal;
          }

          h3 {
            font-weight: 600;
            font-size: 1.05rem;
            text-transform: capitalize;
            margin: 5px 0;
            color: $coal;
          }
        }

        .value {
          p {

          }
        }
      }
    }

    .navs {
      a {
        color: $coal;
        display: inline-block;
        padding: 15px 15px;
      }

      .active {
        background: $secondary-color;
        color: $primary-font-color;
        font-weight: 600;
      }
    }

    .addresses {
      .additional {
        display: flex;
        width: 100%;
        flex-direction: column;

        h2 {
          color: $secondary-color;
          font-size: 1rem;
          font-weight: 900;
          margin: 10px 0;
        }

        .additional-wrapper {
          padding: 10px;
          border: 1px solid $gray;
          border-radius: 4px;
          margin-bottom: 15px;

          .row {
            width: 100%;
            display: flex;

            .column {
              flex: 1 1;
              display: flex;
              flex-direction: column;

              h5 {
                font-size: 1rem;
                font-weight: 600;
                padding: 0 0 10px 0;
              }

              .actions {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;

                a {
                  background: $secondary-color;
                  color: $primary-font-color;
                  font-size: 0.7rem;
                  padding: 7px;
                  font-weight: 700;
                }
              }

              p {
                padding: 0 0 10px 0;
                font-weight: 500;
              }

              .make-default {
                border: none;
                background: $coal;
                color: $primary-font-color;
                font-size: 0.75rem;
                width: 200px;
                padding: 7px 0;
                font-weight: 600;
                cursor: pointer;
              }
            }
          }
        }
      }

      .default {
        border: 1px solid $cloud;
        width: 40%;
        padding: 15px;
        min-width: 300px;
        margin-top: 10px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $cloud;

          h2 {
            color: $secondary-color;
            font-size: 1rem;
            font-weight: 900;
          }

          .actions {
            width: 30%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.85rem;
            padding: 0 5px;

            a {
              font-weight: 600;
              margin-left: 5px;
            }
          }
        }

        .body {
          padding-top: 10px;

          p {
            margin: 5px 0;
            text-transform: capitalize;
          }
        }
      }
    }

    .orders {
      width: 100%;
      padding: 10px 0;

      .order {
        display: flex;
        border-bottom: 1px solid $gray;

        .booking-info {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 80px;
          width: 40%;

          .checkout-mode {
            display: flex;
            font-size: 1rem;
            font-weight: 600;
            padding: 0 10px;
            width: 20%;

            img {
              max-width: 30px;
              height: 30px;
            }
          }

          .address-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            text-align: center;

            .address {
              color: $coal;
              font-size: 0.9rem;
              font-weight: 600;
              width: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .postcode {
              font-size: 1.5rem;
              font-weight: 900;
            }

            .order-id {
              font-size: 0.8rem;
              font-weight: 600;
              color: $gray;
            }
          }
        }

        .payment-info {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20%;
          font-size: 1rem;
          font-weight: 700;

          .amount {
            margin-right: 10px;
          }

          .status {

          }
        }

        .time-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 20%;

          .status {
            font-size: 0.9rem;
          }

          .value {
            font-size: 1rem;
            font-weight: 900;
            color: $success;
          }
        }

        .payment-method {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 20%;

          span {
            margin-bottom: 2px;
            font-size: 1rem;
            font-weight: 500;
          }

          p {
            background: $secondary-color;
            color: $primary-font-color;
            font-weight: 800;
            padding: 12px 5px;
            width: 95%;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 757px) {
  #profile {
    padding: 5px;
    flex-direction: column;


    .profile-summary {
      width: 100% !important;
    }


    .account {
      width: 100% !important;
      box-sizing: border-box;
      padding: 10px 0;
      overflow-x: scroll;

      .orders {
        .order {
          display: block;
          .booking-info {
            display: inline-block;
            width: 100%;

            .checkout-mode {
              display: inline-block;
            }
            .address-details {
              display: inline-block;
            }
          }

          .payment-info {
            display: inline-block;
          }
          .time-info {
            display: inline-block;
          }
          .status {
            display: inline-block;
          }
          .payment-method {
            display: inline-block;
          }
        }
      }

      .new {
        top: 15px;
      }

      h1 {
        font-size: 1.5rem;
      }

      .navs {
        display: flex;

        a {
          font-size: 0.8rem;
          flex: 1;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          padding: 0;
        }
      }

      .addresses {
        .default {
          width: 100%;
          min-width: auto;
        }

        .additional .additional-wrapper {
          .row .column {
            .make-default {
              width: 100%;
            }

            .actions {
              flex-direction: column;
              width: 100%;

              a {
                width: 70%;
                text-align: center;
              }

              button {
                width: 70%;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
