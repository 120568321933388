#order-favorite {
  overflow: hidden;

  .hero {
    background: $primary-color;
    background: linear-gradient(to bottom, $primary-lighter-color 0%, $primary-dark-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efd5a2", endColorstr="#a2916e", GradientType=0);
    margin-top: 30px;
    padding: 25px 20px;
    color: white;
    position: relative;

    p.note {
      width: 50%;
    }

    form {
      width: 50%;
      background: linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dac292", endColorstr="#a2916e", GradientType=0);
      padding: 20px 15px;

      .label {
        color: $primary-font-color;
        font-size: 1.2rem;
        font-weight: 500;
      }

      .form-group {
        width: 100%;

        input {
          background: transparent;
          border: none;
          border-bottom: 2px solid $primary-lighter-color;
          color: $primary-font-color;
          width: 100% !important;
        }

        button {
          background: $primary-dark-color;
          color: $primary-font-color;
          border: none;
          width: 200px;
          height: 50px;
          margin-left: 10px;
          font-weight: 600;
          font-size: 1rem;
          cursor: pointer;
        }

      }
    }

    h1 {
      font-size: 3.5rem;
      font-weight: 900;
      width: 70%;
    }

    p {
      margin: 10px 0;
      font-weight: 500;
    }
  }
  //
  //.hero::after {
  //  content: "";
  //  display: block;
  //  position: absolute;
  //  right: 20px;
  //  bottom: 0;
  //  top: -20px;
  //  width: 50%;
  //  background-image: url('../images/join-team.png');
  //  background-repeat: no-repeat;
  //  background-position: bottom right;
  //  background-size: contain;
  //}

  .info {
    padding: 20px;

    h3 {
      color: $primary-lighter-color;
      font-size: 1rem;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 20px;
      font-weight: 600;
      width: 55%;
    }
  }

  .column {
    flex-direction: column;
  }

  form {
    align-items: flex-start;
    margin-bottom: 30px;

    .form-group {
      width: 85%;

      p {
        width: 30%;
      }

      input[type='text'] {
        width: 70%;
      }

      .radio {
        width: 70%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }

      input[type='radio'] {
        width: 30px;
      }

      textarea {
        width: 70%;
        border: 1px solid $gray;
        font-size: 1.3rem;
      }

    }

    .footer {
      display: flex;
      justify-content: space-between;
      width: 85%;
      margin-top: 25px;

      p {
        font-size: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .text-primary {
          font-size: 2rem;
          line-height: 0px;
        }
      }

      button {
        background: $secondary-color;
        color: $primary-font-color;
        font-size: 1rem;
        border: none;
        padding: 15px;
      }
    }
  }

  #apps {
    padding: 25px;

    .column {
      padding: 0 15px;
    }

    h2 {
      color: $secondary-color;
      font-size: 3rem;
      font-weight: 900;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.5rem;
      margin: 10px 0;
    }

    span {
      font-size: 0.7rem;
    }
  }

  .download {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

    .img {
      border: 1px solid $gray;
      display: flex;
      width: 35%;
      height: auto;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}


@media screen and (max-width: 1000px) {
  #order-favorite {
    padding: 5px;

    .hero {
      form {
        width: 100%;
      }
    }

    .hero::after {
      display: none
    }
  }

  #apps {
    padding: 0;
    flex-direction: column;

    .column {
      padding: 10px 0;
      box-sizing: border-box;
    }
  }
}


@media screen and (max-width: 760px) {
  #order-favorite {
    padding: 5px;

    .hero {
      h1 {
        font-size: 1.5rem;
      }
    }
    #apps {
      padding: 5px;
      h2 {
        font-size: 2rem;
      }
      .column {
        padding: 0;
      }
    }

    .download {
      flex-direction: column;

      .img {
        width: 60%;
        margin: 10px 0;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  #order-favorite {
    .download {
      flex-direction: column;

      .img {
        width: 90%;
        margin: 10px 0;
      }
    }
  }
}
