#our-food {
  padding: 25px;
  h1 {
    color: $secondary-color;
    font-size: 2.5rem;
    font-weight: 900;
    margin: 15px 0;
  }
  .column {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      color: $secondary-color;
      font-size: 1.3rem;
      font-weight: 900;
      margin: 15px 0;
    }
    p {
      font-size: 1.05rem;
      margin: 5px 0;
      font-weight: 500;
    }
  }

  .submenu {
    padding: 0 25px;
    padding-top: 45px;
    margin-top: -45px;
    h2 {
      color: $primary-color;
      font-size: 2rem;
      text-transform: uppercase;
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      padding: 0 25px;

      .item {
        background: $coal;
        border: 2px solid $coal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: normal;
        margin-right: 20px;
        cursor: pointer;
        margin-bottom: 15px;

        &:hover {
          border: 2px solid $secondary-color;
          background: $secondary-color;
        }
        .thumb {
          padding: 4px;
          background: $primary-bg;
          img {
            width: 150px;
            height: 150px;
          }
        }
        .title {
          color: $primary-font-color;
          font-size: 0.7rem;
          font-weight: 800;
          width: 158px;
          padding: 10px 0;
          text-align: center;
          min-height: 27px;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  #our-food {
    padding: 5px;

    .row {
      flex-direction: column;
    }

    .submenu {
      .items {
        justify-content: center;
        .item {
          .thumb {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 473px) {
  #our-food {
    padding: 5px;

    .submenu {
      .items {
        justify-content: center;
      }
    }
  }
}
