#footer {
  z-index: 1;
  position: relative;
}
.footer-wrapper {
  background: $secondary-bg;
  padding: 15px;
  color: $primary-font-color;
  display: flex;
  font-size: 0.9rem;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: column;
  }
  .shop-details {
    text-align: center;
    padding-bottom: 15px;

    h5 {
      font-weight: 900;
      font-size: 1rem;
    }
    .address {
      font-size: 150%;
    }
    .mobile {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}

.footer-left {
}

.shortcuts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}

.shortcuts a {
}

.s-item {
  color: #ffffff;
  text-decoration: none;
  padding: 0 6px;
  border-right: 1px solid;
}

.footer-info {
  font-size: 0.85rem;
  padding: 0 5px;
}

.footer-info p {
  margin: 3px 0;
  max-width: 65%;
}

.social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
}

.social i {
  font-size: 1.7rem;
  margin: 0 8px;
}

.news-letter {
  width: fit-content;
}

.newsletter {
  border: none;
  color: $primary-font-color;
  padding: 15px 15px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-left: 15px;
  margin-top: 10px;
  display: block;
  width: fit-content;
  font-weight: 600;
}


.footer-right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.footer-right a {
  color: white;
  text-decoration: none;
  margin-top: 10px;
}
@media screen and (max-width: 1240px) {
  .s-item {
    padding: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
    .footer-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      .shortcuts {
        display: block;
        text-align: center;
        font-size: 0.8rem;
        height: 40px;
      }
      .footer-info {
        p {
          margin: 3px auto;
          max-width: 100%;
          text-align: center;
          font-size: 0.8rem;
        }
      }
      .news-letter {
        .newsletter {
          border: none;
          color: #ffffff;
          padding: 10px 11px;
          background-color: rgba(0, 0, 0, 0.2);
          margin-left: 15px;
          font-size: 0.7rem;
        }
      }
    }
    .footer-right {
      a {
        font-size: 0.7rem;
        font-weight: 400;
      }
    }
  }
}
@media screen and (max-width: 420) {
 #footer{
    .footer-wrapper {
    .footer-left {
      .shortcuts {
        height: 40px;
      }
    }
  }
 }
}
