#not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
  h1 {
    color: $secondary-color;
    font-weight: 900;
    font-size: 3rem;
    margin-top: 15px;
    text-transform: uppercase;
  }

  p {
    color: $coal;
    font-size: 1.75rem;
    font-weight: 600;
    margin-top: 15px;
  }

  a {
    background: $secondary-color;
    color: $primary-font-color;
    padding: 15px;
    font-weight: bold;
    margin-top: 15px;
  }
}
