#shop-details {
  display: flex;
  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
  }
  .left {
    width: 40%;
    padding: 25px;
    box-sizing: border-box;
    @media screen and (max-width: 960px) {
        width: 100%;
        padding: 15px;
    }
    .header {
      display: flex;
      margin-bottom: 15px;
      h1 {
        font-weight: 900;
        color: $secondary-color;
        font-size: 1.6rem;
      }
      h2 {
        font-weight: 900;
        color: $primary-color;
        font-size: 1.6rem;
        margin-left: 5px;
      }
    }
    .modes {
      padding: 20px 0;
    }

    .shop-specs {
      padding: 20px 0;
      border-bottom: 1px solid $gray;
    }
    .operations {
      padding: 15px;
      font-size: 0.875rem;

      .head {
        display: flex;
        margin-bottom: 8px;

        .o-instore {
          color: $secondary-color;
          font-weight: 600;
        }

        .o-delivery {
          color: $secondary-color;
          font-weight: 600;
        }
      }

      .content {
        display: flex;
        flex-direction: column;

        .o-row {
          display: flex;
          border-bottom: 1px solid #e5e5e5;
          padding: 5px 0;

          span {
            display: block;
          }
        }
      }

      .o-day {
        width: 40%;
        text-transform: capitalize;
        display: flex;
        align-items: center;

        p {
          margin-bottom: 8px;
        }
      }

      .o-time {
        margin-bottom: 8px;
      }

      .o-instore {
        width: 30%;
        display: flex;
        align-items: center;
      }

      .o-delivery {
        width: 30%;
        display: block;
        align-items: center;
      }
    }
  }

  .right {
    width: 60%;
    padding: 25px;
    box-sizing: border-box;
    @media screen and (max-width: 960px) {
        width: 100%;
        padding: 0;
    }
    .contact {
      margin-top: 25px;
      padding: 0 15px;
      h3 {
        color: $secondary-color;
        font-weight: 900;
        font-size: 1rem;
        margin-bottom: 20px;
      }
      a {
        background: $secondary-color;
        border: none;
        color: $primary-font-color;
        padding: 15px 10px;
        font-size: 0.9rem;
        font-weight: 500;
        display: flex;
        width: 100px;
        justify-content: center;
        align-items: center;
      }

    }

    #directions {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 15px;
    }

  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3px 0;
    .label {
      flex: 1;
      font-weight: 600;
    }
    .value {
      flex: 1;
      font-size: 0.85rem;
    }
  }
}
