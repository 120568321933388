@import "header";
@import "footer";
@import "hero";
@import "message";
@import "suggestions";
@import "offers";
@import "~loaders.css/src/animations/line-scale";


* {
  font-family: 'Raleway';
}

#content {
  min-height: 70vh;
}
.discount-price {
  color: black !important;
  font-size: 1rem !important;
  padding: 0 !important;
}
.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 40px 0;
  box-sizing: border-box;
  overflow-y: scroll;
  .content {
    box-sizing: border-box;
    background: $primary-bg;
    width: 70%;
    margin: 0 auto;
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $cloud;
      h2{
        padding: 10px 10px;
        width: 100%;
      }
      .remove {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $cloud;
        font-size: 1.5rem;
        font-weight: 900;
        cursor: pointer;
      }
    }
    .body {
      overflow: hidden;
      display: flex;

      .img {
        width: 25%;
        height: 200px;
        padding: 20px 0;

        img {
          max-width: 150px;
          height: 150px;
        }
      }
      .details {
        overflow-y: scroll;
        width: 80%;
        padding: 20px 10px;
        p {
          margin-bottom: 10px;
        }
        h3 {
          color: $secondary-color;
          font-weight: 900;
          font-size: 1rem;
          margin-bottom: 10px;
        }
        .product {
          p {}
        }
        .allergen {
          display: flex;
          align-items: center;
          padding: 4px 0;
          img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}

.last {
  border-right: 0;
}

.active-nav {
  border-bottom: 3px solid $primary-color !important;
  padding-bottom: 2px !important;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    padding: 0 0 0 5px;
    font-size: 1rem !important;
    text-transform: capitalize;

    span {
      color: $primary-color;
      font-size: 1.5rem;
    }
  }

  .form-input {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 75%;
  }

  .form-prefix {
    display: inline-block;
    color: #444444;
    font-size: 2rem;
    font-weight: 600;
    padding: 0 5px 0 0;
  }

  input {
    padding: 5px 9px;
    height: 40px;
    border: 1px solid $gray;
    margin: 5px 0;
    color: $coal;
    font-size: 1rem;
    &::placeholder {
      text-transform: capitalize;
    }
  }

}

@for $i from 1 through 10 {
  .f-#{$i} {
    flex: $i !important;
  }
}

.column {
  display: flex;
  flex: 1;
}

.row {
  display: flex;
  width: 100%;
}

.text-primary {
  color: $primary-color;
}

#sticky {
  padding-top: 60px;

  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .find-item-cart {
    position: fixed;
    top: 45px;
    width: 22% !important;
    z-index: 1;
    background: $primary-bg;
  }

  .cart {
    position: fixed;
    top: 60px;
    width: 22.5% !important;
    z-index: 1;
    background: $primary-bg;
  }

  .menu {
    position: fixed;
    top: 60px;
    width: 23.5% !important;
    z-index: 0;
    background: $primary-bg;
  }
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-66 {
  width: 66% !important;
}

.w-50 {
  width: 50% !important;
}

.w-33 {
  width: 33% !important;
}

.w-25 {
  width: 25% !important;
}

.f-show {
  display: flex !important;
}

.hidden {
  display: none !important;
}

.loader-wrapper {
  width: 100%;
  height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;

  .line-scale > div {
    width: 5px;
    height: 75px;
    background: $secondary-color;
  }
}

.alert-info {
  p {
    width: 100%;
    font-size: 0.7rem;
    color: $coal;
    text-align: center;
    padding-top: 25px;
  }
}

.f-c {
  justify-content: center;
}

.alert {
  padding: 10px 15px;
  margin: 5px;
}
.alert-info {
  background: #d9edf7;
}
.alert-success {
  background: #dbf7d9;
}

.alert-danger {
  background: #e8d1d1;
}


#response-modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    width: 30%;
    background: $cloud;
    margin: auto;
    .header {
      color: $primary-font-color;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      font-size: 1rem;
      box-sizing: border-box;
      font-weight: 400;
      line-height: 1rem;
      padding: 10px 5px;
      position: relative;
      overflow: hidden;

      span {
        background: $cloud;
        color: $secondary-color;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .approved {
      background: $success;
    }
    .rejected {
      background: $primary-lighter-color;
    }
    .initial {
      background: $info;
    }
    .body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $secondary-color;
        width: 50%;
        color: $primary-font-color;
        padding: 10px 0;
        font-weight: 700;
        font-size: 1rem;
      }
    }
  }
}
.toggle-checkout {
  display: none;
  justify-content: center;
  .checkout-wrapper {
    max-width: 280px;
    width: 100%;
    margin: 5px 0;
  }
}
.checkout-mode-label {
  font-size: 0.8rem;
  margin-top: 10px;
  margin-bottom: 5px;
}
.checkout-mode {
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  padding: 0;

  .mode {
    background: $secondary-color;
    display: flex;
    flex: 1 1;
    box-sizing: border-box;
    height: 30px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
  }
  .delivery {
  }
  .takeaway {
    border-left: 5px solid $primary-bg;
  }

  p {
    font-size: 0.8rem;
    padding: 0 0 0 10px;
    text-align: start;
    font-weight: 900;
    color: $primary-font-color;
  }
  span {
    background: $primary-color;
    color: $primary-font-color;
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.price-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#sticky{
  #content{
    padding-top: 60px;;
    display: block;
    position: relative;
  }
}
.discount-price {
  font-weight: 600;
  margin-right: 4px;
}

.Toastify__toast {
  font-family: $primary-font;
}

#items-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
  h1 {
    color: $secondary-color;
    font-weight: 900;
    font-size: 3rem;
    margin-top: 15px;
    text-transform: uppercase;
  }

  p {
    color: $coal;
    font-size: 1.75rem;
    font-weight: 600;
    margin-top: 15px;
  }

  a {
    background: $secondary-color;
    color: $primary-font-color;
    padding: 15px;
    font-weight: bold;
    margin-top: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .checkout-mode {
    padding: 0;
  }

  .checkout-mode-label {
    padding: 0;
  }
  .toggle-checkout {
    display: flex;
  }
}
@media screen and (max-width: 760px) {
  .form-group .label {
    font-size: 0.8rem !important;

    span {
      font-size: 0.8rem;
    }
  }
}


