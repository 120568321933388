#header {
  position: relative;
}

#header .topbar:after {
  content: "";
  display: block;
  background: $primary-color;
  height: 38px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.topbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 455px;
  height: 92px;
  display: block;
  float: left;
  position: relative;
  z-index: 2;
  margin-left: 10px;
  background-image: url('../images/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}

.app-wrapper {
  z-index: 2;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  padding-top: 2px;
  padding-right: 20px;
}

.android {
  height: 40px;
  display: block;
  float: right;
  background-size: contain;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin: 0 0 0 15px;
  padding: 0;
  border: 0;
  width: 139px;
  background-image: url('../images/icon-app-store-android.png');
}

.ios {
  height: 40px;
  display: block;
  float: right;
  background-size: contain;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin: 0 0 0 15px;
  padding: 0;
  border: 0;
  width: 139px;
  background-image: url('../images/icon-app-store-apple.png')
}

.navbar {
  background: $secondary-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $primary-font-color;
  z-index: 1;
  height: 50px;

  .actions {
    display: flex;
    .search-form {
      display: flex;
      @media screen and (max-width: 900px) {
        display: none;
      }
      .search-label {
          display: flex;
          justify-content: center;
          align-items: center;
          .label {
            padding: 0 10px;
          }
          i {
            font-size: 1.3rem;
          }
        }
        .search-input {
          background: $secondary-dark-color;
          border: none;
          width: 200px;
          padding: 0 15px;
          font-size: 0.9rem;
          font-family: 'Raleway'; 
          color: $cloud;
          text-transform: uppercase;
          &::placeholder {
            color: $gray;
          }
        }
        .search-button {
          background-color: $secondary-dark-color;
          border: none;
          margin-right: 15px;
          padding: 0 15px;
          color: $primary-bg;
          font-weight: 600; 
          border-left: 1px solid $gray;
        }
    }
    .search-link {
      display: none;
      @media screen and (max-width: 900px) {
          display: flex;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        .label {
          padding: 0 10px;
          @media screen and (max-width: 760px) {
              display: none;
          }
        }
      
        i {
          font-size: 1.3rem;
          @media screen and (max-width: 760px) {
              padding: 0 10px;
          }
        }
      }
    }
    .toggle-menu {
      display: none;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      margin: 0 10px;
      @media screen and (max-width: 760px) {
        display: flex;
      }
      i {
        font-size: 1.3rem;
      }
    }
  }
}

.nav {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  margin: 0;

  .dropbtn {
    border: none;
    display: flex;
    flex: 1 1;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }

  .dropdown {
    position: relative;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: $secondary-dark-color;
    min-width: 190px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 50px;
    left: 0;
    padding-inline-start: 0;
  }
  .dropdown-content-shop {
    display: none;
    position: absolute;
    background-color: $secondary-dark-color;
    min-width: 230px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 44px;
    left: 0;
    padding-inline-start: 0;
  }

  .dropdown-content a {
    color: $primary-font-color;
    text-decoration: none;
    display: block;
    padding: 15px 20px;
  }


  .dropdown-content a:hover {
    background-color: $secondary-dark-color!important;
  }

  .dropdown:hover .dropdown-content {display: block;}

  .dropdown-content-shop a {
    color: $primary-font-color;
    text-decoration: none;
    display: block;
    padding: 15px 20px;
  }


  .dropdown-content-shop a:hover {
    background-color: $secondary-dark-color!important;
  }

  .dropdown:hover .dropdown-content-shop {display: block;}

}


.nav-item {
  display: flex;
  border-right: 1px solid;
  height: 50px;
  justify-content: center;
  align-items: center;
  padding: 0 0;
  font-weight: 500;
  cursor: pointer;

  li {
    list-style: none;
  }
  a {
    padding-bottom: 5px;
  }
  span {
    padding-bottom: 5px;
  }
  button {
    color: white;
    background: transparent;
    border: none;
    font-weight: 500;
    font-size: 1rem;
  }
}


.nav-item:hover {
  background: $secondary-dark-color;
}

.shop-nav {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  margin: 0;

  .nav-item {
    position: relative;
    padding: 0 10px;

    input {
      width: 100%;
      border: none;
      height: 50px;
      font-size: 1rem;
      color: white;
      padding: 0 5px;
      background: #00000021;
    }

    input:focus {
      background: #08080869;
    }

    i {
      font-size: 1.7rem;
    }

    button {
      background: transparent;
      border: none;
      position: absolute;
      right: 10px;
      color: $primary-font-color;
      cursor: pointer;
    }

    span {
      position: relative;
    }

    .cart-count {
      background: $primary-color;
      position: absolute;
      top: -11px;
      right: -2px;
      padding: 1px 6px;
      font-size: 0.9rem;
      border-radius: 2px;
      font-weight: 600;
    }
  }

  .search-menu {
    padding: 0;
    width: 215px;
  }

}

.download-apps {
  display: none;
  font-weight: 600;
}

.toggle-nav {
  display: none;
}

.nav-logo {
  display: none;
}


@media screen and (max-width: 1240px) {
  .logo {
    width: 303px;
    height: 95px;
  }

  .android {
    width: 87px;
  }

  .ios {
    width: 87px;
  }
  #header .topbar:after {
    content: "";
    display: block;
    height: 24px;
    position: absolute;
    bottom: 14px;
  }
  .topbar {
    padding-top: 0;
    margin-bottom: 0;
  }
  .navbar {
    margin-top: -5px;
  }
  .nav-item {
    font-weight: 400;
    font-size: 0.875rem;
  }
  //.nav-item:after {
  //  content: "";
  //  display: block;
  //  position: absolute;
  //  right: 9px;
  //  top: 50%;
  //  margin-top: -3px;
  //  border-top: 4px solid #ffffff;
  //  border-left: 4px solid transparent;
  //  border-right: 4px solid transparent;
  //}
  // .nav-item:before{
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   right: 9px;
  //   top: 50%;
  //   margin-top: -1px;
  //   border-top: 4px solid #10106a;
  //   border-left: 4px solid transparent;
  //   border-right: 4px solid transparent;
  // }
}


@media screen and (max-width: 815px) {
  .nav-item {
    padding: 0 10px;
  }
}

@media screen and (max-width: 760px) {

  #toggled-navbar {
    .nav {
      background: $secondary-color;
      display: block;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      z-index: 99;
      border-top: 1px solid $cloud;
    }
  }
  .topbar {
    display: none;
  }

  .navbar {
    justify-content: flex-end;

    .nav-logo {
      display: block;
      position: absolute;
      background-image: url('../images/logo-small.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 2px 9px;
      z-index: 1;
      left: 0;
      top: 0;
      bottom: 0;
      width: 180px;
    }
  }

  .nav {
    flex-direction: column;
    width: 100%;
    display: none;
    position: relative;

    .nav-item {
      width: 100%;
    }
  }

  .shop-nav {
    padding: 0;
    height: 50px;

    .search-menu {
      display: none !important;
    }
    .nav-menu {
      display: none;
    }
    .nav-item {
      padding: 0 15px;
      display: flex;
      height: 50px;
      border-bottom: none;

      i {
        font-size: 1.4rem;
      }

      .cart-count {
        top: -5px;
        right: -5px;
      }
    }
  }
  .nav-item {
    border-bottom: 1px solid $coal;
  }

  .download-apps {
    display: block;
  }
  .toggle-nav {
    display: block;
  }
}

@media screen and (max-width: 440px) {
  .navbar {
    .nav-logo {
      background-position: 2px 11px;
      width: 120px;
    }
  }
}
