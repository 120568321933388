#forget-password {
  padding: 20px;
  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3 {
      font-size: 1.5rem;
      font-weight: 300;
      padding: 10px 0;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .w-50.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: initial;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: $coal;
      font-size: 1rem;
      margin-top: 20px;
      cursor: pointer;
      margin-bottom: 30px;
    }
  }

  form {
    background: $cloud;
    justify-content: space-around !important;

    input {
      margin: 25px 20px;
      height: 40px;
      padding: 5px 15px;
      font-size: 1.2rem;
      font-weight: 300;
    }
  }

  button {
    background: $secondary-color;
    border: none;
    color: $primary-font-color;
    width: 250px;
    margin: 0 25px;
    height: 50px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
  }

}

#reset-password {
  padding: 20px;
  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3 {
      font-size: 1.5rem;
      font-weight: 300;
      padding: 10px 0;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .w-50.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: initial;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: $coal;
      font-size: 1rem;
      margin-top: 20px;
      cursor: pointer;
      margin-bottom: 30px;
    }
  }

  form {
    background: $cloud;
    justify-content: space-around !important;

    input {
      height: 40px;
      padding: 15px 15px;
      font-size: 1.2rem;
      font-weight: 300;
      margin-top: 15px;
    }
  }

  button {
    background: $secondary-color;
    border: none;
    color: $primary-font-color;
    height: 50px;
    font-size: 0.9rem;
    font-weight: 600;
    margin: 15px 0;
    cursor: pointer;
  }

}
