#franchise {
  .hero {
    background: #d23013;
    background: linear-gradient(to bottom, #d23013 0%, #4b0e03 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d61a0c", endColorstr="#4b0e03", GradientType=0);
    color: $primary-font-color;
    padding: 15px;
    margin-top: 25px;

    h1 {
      font-size: 2.875em;
      line-height: 1;
      margin-bottom: 15px;
      font-weight: 900;
    }

    h3 {
      font-size: 200%;
      line-height: 1.2;
      margin-bottom: 15px;
    }

    p {
      font-size: 1rem;
      font-weight: 500;
      margin: 0 0 15px;
      line-height: 1.4;
    }
  }


  .row-2 {
    padding: 25px;
    h1 {
      font-size: 2.25em;
      margin-bottom: 15px;
      color: $primary-color;
      font-weight: 900;
    }

    .row {
      display: flex;
      justify-content: flex-start;

      .w-50:nth-child(2) {
        border: 2px solid $gray;
        height: fit-content;
        h3 {
          font-size: 2rem;
          font-weight: 800;
          color: $secondary-color;
        }
      }
      .w-50 {
        padding: 7px;
        .img-wrapper {
          width: 100%;
          height: 300px;
          img {
            max-width: 100%;
            height: auto;
          }
        }
        p {
          font-size: 1rem;
          font-weight: 500;
          padding-right: 20px;
          margin-bottom: 10px;
        }

        .form-group {
          input {
            width: 60%;
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          p {
            font-size: 0.7rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .text-primary {
              font-size: 2rem;
              line-height: 0px;
            }
          }
          button {
            background: $secondary-color;
            color: $primary-font-color;
            font-size: 1rem;
            border: none;
            padding: 15px;
          }
        }
      }
    }
  }

  .row-3 {
    background-color: #10106a;
    color: #ffffff !important;
    h2 {
      font-size: 2.25em;
      margin-bottom: 15px;
      font-weight: 800;
      padding: 15px 0 0 20px;
    }
    .row {
      &:after {
        content: " ";
        display: table;
      }
      .w-50 {
        padding: 0 20px;
        .list-faqs {
          margin: 0;
          padding: 0 15px 0 0;

          li {
            display: block;
            list-style: none;
            padding: 0 0 0 30px;
            margin: 0 0 15px;
            position: relative;
            font-weight: 400;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);

            &:before {
              content: "Q";
              font-size: 1.5em;
              margin: 0;
              position: absolute;
              top: -7px;
              left: 0;
              font-weight: 900;
            }
          }
            strong {}
            ul {
              margin: 15px 25px;
              padding: 0;
              list-style: none;
              li {
                font-weight: 400;
                border-bottom: 0 none;
                margin-bottom: 0;

                &:before {
                  content: "A";
                }
                ul {
                  margin: 0 0 0 5px;
                  li {
                    padding-left: 15px;
                    margin-bottom: 7px;
                    line-height: 1.5;

                    &:before {
                      display: inline-block;
                      content: "•";
                      margin-left: -16px;
                      margin-right: 10px;
                      font-size: 1em;
                      font-weight: 900;
                      top: auto;
                      left: auto;
                      position: relative;
                  }
                }
                p {
                  margin: 0 0 15px;
                  line-height: 1.4;
                }
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 760px) {
  #franchise {
    .hero {
      h1 {
        font-size: 1.875em;
      }
      h3 {
        font-size: 150%;
        line-height: 1;
      }
    }
    .row {
      flex-direction: column;
      width: 95%;
    }
    .row-2 {
      padding: 5px;
      h1 {
        font-size: 1.55em;
      }
      .row .w-50 {
        width: 100% !important;
      }
    }
    .row-3 {
      overflow: hidden;
      .row {
        .w-50 {
          width: 100% !important;
          .list-faqs p {
            padding-left: 15px;
          }
        }
      }
    }
    .row-3 .row .w-50 .list-faqs ul{
      margin: 15px 0;
      li {
        padding-left: 5px;
      }
    }

    form {
      align-items: center;
    }
  }
}
