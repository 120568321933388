#contact-us {
  .hero {
    background: #dac292;
    background: linear-gradient(to bottom, $primary-color 0%, $primary-dark-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dac292", endColorstr="#a2916e", GradientType=0);
    margin-top: 30px;
    padding: 25px 20px;
    color: white;
    position: relative;

    h1 {
      font-size: 3.5rem;
      font-weight: 900;
    }

    p {
      margin: 10px 0;
      font-weight: 500;
    }
  }

  .hero::after {
    content: "";
    display: block;
    position: absolute;
    right: 20px;
    bottom: 0;
    top: -20px;
    width: 590px;
    background-image: url('../images/join-team.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
  }

  .info {
    padding: 20px;

    h3 {
      color: $primary-lighter-color;
      font-size: 1rem;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 20px;
      font-weight: 600;
      width: 55%;
    }
  }

  .column {
    flex-direction: column;
  }

  form {
    align-items: flex-start;
    margin-bottom: 30px;

    .form-group {
      width: 85%;

      p {
        width: 30%;
      }

      input[type='text'] {
        width: 70%;
      }

      .radio {
        width: 90%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }

      input[type='radio'] {
        width: 18px;
        margin-right: 10px;
      }

      textarea {
        width: 70%;
        border: 1px solid $gray;
        font-size: 1.3rem;
      }


    }

    .footer {
      display: flex;
      justify-content: space-between;
      width: 85%;
      margin-top: 25px;

      p {
        font-size: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        .text-primary {
          font-size: 2rem;
          line-height: 0px;
        }
      }

      button {
        background: $secondary-color;
        color: $primary-font-color;
        font-size: 1rem;
        border: none;
        padding: 15px;
      }
    }

    .recaptcha {
      margin-top: 10px;
      width: 85%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

@media screen and (max-width: 992px) {
  #contact-us {
    .row {
      flex-direction: column;
    }

    form {
      align-items: center;
    }
  }
}


@media screen and (max-width: 760px) {
  #contact-us {
    padding: 5px;

    form {
      align-items: center;
      .form-group {
        width: 100%;
      }
      .recaptcha {
        width: 100%;
      }
    }

    .info {
      padding: 5px;
    }

    .row {
      flex-direction: column;
    }

    .hero {
      form {
        width: 100%;
      }
    }

    .hero::after {
      display: none
    }

    .items {
      justify-content: center;

      .item {
        .thumb {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
