#checkout {
  .steps {
    border-bottom: 4px solid $secondary-color;
    padding: 0 25px;
    margin: 25px 0 0 0;

    a {
      background: $cloud;
      color: $primary-font-color;
      display: inline-block;
      padding: 20px 30px;
      margin-left: 2px;
      cursor: pointer;

      p {
        font-size: 0.75rem;
        text-shadow: 0 1px 2px #dedede;
      }

      h4 {
        font-size: 1.15rem;
        text-shadow: 0 1px 2px #dedede;
      }
    }

    .done {
      background: $secondary-lighter-color;

      h4 {
        text-shadow: none;
      }
    }

    .active {
      background: $secondary-color;

      h4 {
        text-shadow: none;
      }
    }
  }

  .content {
    .step-1 {
      display: flex;
    }

    .step-2 {
      display: flex;
      flex-direction: column;
      padding: 25px;

      .disabled {
        background: $cloud !important;
      }

      .user-addresses {
        padding: 5px;

        h2 {
          color: $secondary-color;
          font-weight: 900;
          margin-bottom: 10px;
        }

        .address-suggestions {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          a {
            background: $cloud;
            color: $coal;
            font-weight: 700;
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 15px 0;
            padding: 0 10px;
            cursor: pointer;
            font-size: 1.2rem;
            border: none;
            height: 60px;
          }

          .address-suggestion {
            border: 1px solid $cloud;
            min-width: 30%;
            margin-right: 15px;
            padding: 5px 10px;
            position: relative;

            .validating {
              position: absolute;
              background: rgba(255, 255, 255, 0.9);
              display: flex;
              justify-content: center;
              align-items: center;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            }

            .name {
              font-weight: 900;
              text-transform: uppercase;
              font-size: 1.1rem;
              margin: 5px 0;
            }

            .address {
              font-size: 1.3rem;
              margin-bottom: 5px;
            }
          }

          .selected {
            border: 3px solid $secondary-color;
            padding: 3px 8px;
          }
        }
      }

      .instructions {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        strong {
          padding: 5px 0;
          color: $coal;
        }

        textarea {
          border-color: $gray;
        }
      }

      .row {
        display: flex;
        justify-content: flex-end;

        select {
          -webkit-appearance: none;
        }

        .alert {
          margin: 0 15px;
        }

        .continue {
          background: $secondary-color;
          color: $primary-font-color;
          border: none;
          padding: 15px;
          font-weight: 700;
          margin-top: 15px;
          cursor: pointer;
        }
      }
    }

    .step-3 {
      display: flex;

      .customer-summray {
        padding: 10px;
        border: 1px solid $gray;
        border-radius: 4px;
        margin: 25px;

        .body {
          padding: 10px;
        }

        .footer {
          padding: 10px;


          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

          }
        }
      }

      .order-summary {
        padding: 10px;
        border: 1px solid $gray;
        border-radius: 4px;
        margin: 25px;

        .item {
          display: flex;
          border-bottom: 1px solid $gray;
          padding-bottom: 5px;
          color: $coal;
          flex-direction: column;

          .header {
            display: flex;
            color: $secondary-color;
            border-bottom: none;

            .title {
              width: 80%;
            }

            .price {
              width: 20%;
            }
          }

          .body {
            flex: 9;

            .title {
              color: $secondary-color;
              font-size: 1.1rem;
              margin-top: 5px;
            }

            .tier {
              padding: 1px 20px;
              font-size: 0.95rem;

              strong {
                margin-right: 3px;
              }

              ul {
                margin: 0;
              }
            }
          }

          .price {
            flex: 1 1;
            display: flex;
            justify-content: center;
            padding-top: 10px;
            font-weight: 700;
          }
        }
      }


      .notes {
        color: $coal;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        border-bottom: 1px solid $gray;

        h5 {
          font-size: 1rem;
        }

        p {
          font-size: 1rem;
        }
      }

      .summary {
        display: flex;
        flex-direction: column;
        font-size: 1.1rem;
        color: $coal;

        .row {
          display: flex;
          justify-content: space-between;
          padding: 3px 0;
        }

        .value {
          padding-right: 10px;
          font-weight: 500;
        }

        .total {
          padding-right: 10px;
          font-weight: 500;
          color: $primary-color
        }
      }

      .marketing {
        padding: 10px;
        border: 1px solid $gray;
        border-radius: 4px;
        margin-top: 20px;

        p {
          font-size: 1rem;
          margin: 10px 0;
        }

        .form-group {
          justify-content: flex-start;
          margin: 10px 0;

          input {
            font-size: 1rem;
            width: 30px;
            margin-right: 15px;

          }
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;

        button {
          color: $primary-font-color;
          font-weight: 700;
          justify-content: center;
          align-items: center;
          display: flex;
          margin: 15px 0;
          width: 44%;
          cursor: pointer;
          font-size: 1.2rem;
          border: none;
          height: 60px;
        }

        a {
          color: $primary-font-color;
          font-weight: 700;
          justify-content: center;
          align-items: center;
          display: flex;
          margin: 15px 0;
          width: 44%;
          cursor: pointer;
          font-size: 1.2rem;
          border: none;
          height: 60px;
        }

        .cash {
          background: $coal;
        }

        .online {
          background: $secondary-color;
        }
      }
    }

    .active {
      display: flex;
      padding: 20px;
      flex-wrap: wrap;
    }

    .header {
      border-bottom: 2px solid $gray;
      padding: 5px 0;
      width: 100%;
      margin-bottom: 5px;

      h3 {
        color: $secondary-color;
        font-size: 1rem;
        font-weight: 900;
      }
    }

    .addresses {
      border: 1px solid #a9a9a9;
      width: 100%;
      margin: 10px 14px;
      height: 40px;
      padding: 0 5px;
    }

    .form-group {
      input {
        width: 100%;
        height: 30px;
      }
    }

    .column {
      flex-direction: column;
      padding: 0 10px;
    }

    #login {
      flex: 2;
      margin: 20px;

      input {
        width: 60%;
      }

      .card {
        background: $cloud;
        padding: 15px;
        border: 2px solid $gray;

        .body {
          padding: 10px 0;

          p {
            font-size: 1rem;
          }

        }

        .footer {
          padding: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          h3 {
            font-size: 0.8rem;
            font-weight: 600;
            margin-right: 10px;
          }

          a {
            font-size: 0.8rem;
            font-weight: 600;
            margin-right: 10px;
          }

          button {
            background: $secondary-color;
            border: none;
            color: $primary-font-color;
            height: 50px;
            width: 80px;
            font-weight: 600;
          }
        }
      }
    }

    .not-member {
      margin: 35px 20px;

      .body {
        padding: 0 15px;

        p {
          margin: 10px 0;
          font-size: 1.15rem;
        }

        li {
          font-size: 1.15rem;
          margin: 10px 0;
        }
      }

      .footer {
        padding: 15px 0;

        .register {
          background: $secondary-color;
          color: $primary-font-color;
          padding: 15px;
          font-size: 0.9rem;
          font-weight: 700;
          margin-right: 20px;
        }

        .guest {
          color: $coal;
          padding: 15px;
          font-size: 0.9rem;
          font-weight: 700;
          margin-right: 20px;
          border: none;
          background: transparent;
          cursor: pointer;
        }
      }
    }
  }

  .column {
    flex: 1;
  }
}

@media screen and (max-width: 1000px) {
  #checkout .content .not-member {
    margin: 0;
  }
  #checkout .content #login {
    margin: 0;
  }
  #checkout .content .not-member .footer .register {
    margin: 10px 0;
    font-size: 0.8rem;
  }

  #checkout .content .not-member .footer .guest {
    margin: 10px 0;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 757px) {
  #checkout {
    .content .step-3 .customer-summray {
      margin: 0;
    }

    .content .step-3 .order-summary {
      margin: 0;
    }

    #login {
      margin: 0;
    }

    .steps {
      padding: 0;

      a {
        padding: 10px;
        box-sizing: border-box;
        margin-left: 0;

        h4 {
          font-size: 0.8rem;
        }

        p {
          font-size: 0.65rem;
        }
      }

      .step-1 {
        width: 40%;
      }

      .step-2 {
        width: 40%;
      }

      .step-3 {
        width: 20%;
      }
    }

    .content {
      .column {
        padding: 5px;
      }

      .form-group {
        input {
          width: 65%;
        }
      }

      .addresses {
        margin: 15px 0;
      }

      .not-member {
        .body {
          padding: 0;
        }

        .footer {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .guest {
          }
        }
      }

      .step-1 {
        flex-direction: column;
        padding: 5px;
      }

      .step-2 {
        padding: 5px;

        .row {
          flex-direction: column;
        }
      }

      .step-3 {
        flex-direction: column;
        padding: 5px;

        .actions {
          button {
            width: 100%;
          }

          a {
            width: 100%;
          }
        }
      }
    }
  }
}
