.offers {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  text-align: center;
  position: relative;
  padding: 0 0 25px 0;
  .offer {
    .header {
      border: 5px solid $secondary-color;
      padding: 7px 0;
      font-size: 1.7rem;
      font-weight: 900;
      color: $primary-color;
    }
    .img {
      img {
        width: 350px;
      }
    }
    .ingredients {
      color: $primary-font-color;
      h3 {
        border-bottom: 3px solid $primary-font-color;
        padding: 10px 0;
        font-size: 1.4rem;
        font-weight: 800;
      }
      p {
        margin: 8px 0;
      }
    }
  }
  .offer-bg {
    background: $secondary-color;
    bottom: 0;
    left: 0;
    right: 0;
    height: 55%;
    position: absolute;
    z-index: -1;
  }
}

@media screen and (max-width: 1240px) {
  .offers {
    .offer {
      .ingredients {
        h3 {
          border-bottom: 2px solid #ffffff;
          font-size: 1.2rem;
          font-weight: 900;
          margin-bottom: 18px;
        }
        p {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .header {
        border: 3px solid $secondary-color;
      }
      .offer-bg {
        height: 67%;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .offers {
    .offer-bg {
      height: 66%;
    }
    .offer {
      .header {
        font-size: 1.2rem;
      }
      .img {
        margin-top: 14px;
        img {
          width: 270px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .offers {
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 0px;
    .offer-bg {
      display: none;
    }
    .offer {
      background-color: $secondary-color;
      display: flex;
      align-items: flex-start;
      margin: 2px 0px;
      padding: 16px 0px;
     

      .header-image-wraper {
        flex: 1 1 40%;
        padding-left: 20px;

        .header {
          font-size: 1.2rem;
          color: white;
          border: 1px solid #ffff;
        }
        .img {
          img {
            width: 100%;
          }
        }
      }

      .ingredients {
        flex: 1 1 70%;
        padding-left: 20px;
        text-align: left;
        h3 {
          border-bottom: 0;
        }
      }
    }
  }
}
