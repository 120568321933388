#message {
  background: $primary-color;
  color: $primary-font-color;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 65vh;
  padding: 20px;
  justify-content: space-between;

  .message {
    font-size: 3.5rem;
    font-weight: 900;
    text-transform: uppercase;
  }
  .description {
    margin: 25px 0;
    font-size: 1.8rem;
    font-weight: 600;
  }
  .thanks {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: right;
    text-transform: capitalize;
  }
}
