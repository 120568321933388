#cookies {
  padding: 25px;
  .row {
    display: flex;
    h1 {
      color: $secondary-color;
      font-size: 3rem;
      font-weight: 900;
    }
    .column {
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      h4 {
        color: $secondary-color;
        margin-top: 15px;
        font-size: 1rem;
        font-weight: 900;
      }
      p {
        font-size: 1rem;
        margin-bottom: 25px;
      }
    }
  }
}


@media screen and (max-width:760px)  {
  #cookies {
    padding: 5px;
    .row {
      flex-direction: column;

      .column {
        padding: 0;
        text-align: justify;
      }

      ul {
        padding-left: 20px;
      }
      h1 {
        font-size: 2rem;
        margin-bottom: 5px;
      }
    }
  }
}
