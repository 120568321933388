#product {
  display: flex;
  padding-bottom: 40px;

  .img {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 15px;
    margin-top: 30px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .customize {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    padding: 0 20px;
    flex-direction: column;
    width: 100%;

    .head {
      width: 80%;

      h1 {
        color: $secondary-color;
        font-size: 2.7rem;
        font-weight: 900;
      }

      p {
        font-weight: 600;
      }
    }

    .tiers {
      border: 1px solid $gray;
      width: 80%;
      margin-top: 25px;
      border-radius: 9px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .head {
        flex: 1;
        width: auto;
        padding: 15px;

        h5 {
          color: $coal;
          font-size: 1rem;
          font-weight: 800;
        }
      }

      .tier-selected {
        background: rgba($secondary-color, 0.2);
      }

      .tier {
        border-top: 1px solid gray;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .tier-disabled {
          background: rgba($primary-bg, 0.7);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        @keyframes fadeIn {
          from {opacity: 0}
          to {opacity: 1}
        }

        .check-box {
          padding-right: 10px;
          color: $success;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          animation: 1s ease-out 0s 1 fadeIn;
        }

        .clickables {
          padding: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          .clickable {
            background: #10106a;
            color: #ffffff;
            font-size: 1.05rem;
            font-weight: 500;
            margin-left: 5px;
            display: flex;
            height: 40px;
            justify-content: center;
            align-items: center;

            .remove-clickable {
              background: $primary-color;
              color: $primary-font-color;
              border: none;
              width: 40px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1.5rem;
            }

            .title {
              padding: 0 10px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        select {
          border: none;
          width: 98%;
          padding: 5px 22px;
          font-size: 1rem;
          color: #585858;
          height: 55px;
          -webkit-appearance:none;
          text-transform: capitalize;
          background: transparent;
        }

        .required {
          color: $primary-color;
          font-size: 3rem;
          position: absolute;
          top: 11px;
          height: 31px;
          left: 5px;
        }

        .addons {
          width: 100%;
          color: $coal;
          .header {
            border-bottom: 1px solid $cloud;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .max-addons {
              display: flex;
              font-size: 0.8rem;
              padding: 0 5px;
            }

            .free-addons {
              display: flex;
              font-size: 0.8rem;
              padding: 0 10px;
            }
            p {
              margin-right: 6px;
            }

            .title {
              padding: 10px 25px;
              text-transform: capitalize;
            }
          }
          .selected-addons {
            display: flex;
            flex-wrap: wrap;
            padding: 3px 2px;
            p {
              width: 100%;
              padding: 0 5px;
            }
            .selected {
              background: $cloud;
              display: flex;
              margin: 3px 4px;
              align-items: center;
              padding: 0;
              font-size: 0.8rem;
              p {
              }
              span {
                display: flex;
                button {
                  border: none;
                  color: $primary-font-color;
                  font-weight: 900;
                  cursor: pointer;
                }
                input {
                  text-align: center;
                  width: 14px;
                  border: 1px solid $gray;
                  padding: 3px 2px;
                  font-size: 0.7rem;
                  font-weight: 700;
                }
                .add {
                  background: $success;
                }
                .remove {
                  background: $error;
                }
              }

              .delete {
                background: $primary-color;
                color: $primary-font-color;
                border: none;
                font-weight: 900;
                cursor: pointer;
              }
            }
          }
          .body {
            .addon {
              border-bottom: 1px solid $cloud;
              display: flex;
              align-items: center;
              padding: 1px 8px 1px 0;
              cursor: pointer;

              .paid-quantity {
                color: $error;
                font-size: 1.2rem;
                font-weight: 700;
                padding: 5px;
                width: 10px;
              }

              .free-quantity {
                color: $success;
                font-size: 1.2rem;
                font-weight: 700;
                padding: 5px;
                width: 10px;
              }

              .remove-addon {
                color: $error;
              }
              .addon-title {
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 5px;
              }
              button {
                color: $primary-font-color;
                background: $secondary-color;
                border: none;
                height: 30px;
                width: 40px;
                cursor: pointer;
                font-size: 0.8rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: $primary-color;
        font-weight: 900;
        padding-left: 10px;
        width: 95px;
        display: flex;
        font-size: 1.2rem;
        align-items: center;
      }

      .add-to-cart {
        border: none;
        background: $primary-color;
        height: 50px;
        border-bottom-right-radius: 6px;
        color: $primary-font-color;
        font-weight: 700;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100px;
      }

      p {
        color: $primary-color;
        padding-right: 11px;
        font-weight: 700;
        font-size: 0.8rem;
      }

      .quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;

        .increment {
          background: $success;
          color: $primary-font-color;
          border: none;
          width: 30px;
          height: 29px;
          cursor: pointer;
        }
        .decrement {
          background: $error;
          color: $primary-font-color;
          border: none;
          width: 30px;
          height: 29px;
          cursor: pointer;
        }

        .label {
          margin-right: 3px;
        }

        input {
          width: 25px;
          height: 25px;
          text-align: center;
          font-size: 1rem;
          font-weight: 600;
          border: 1px solid gray;
        }
      }
    }

    .note {
      width: 80%;

      p {
        padding: 10px 10px;
        font-size: 0.7rem;
      }
    }

    .actions {
      color: $coal;
      width: 80%;
      font-size: 1.3rem;

      a {
        cursor: pointer;
      }

      i {
        margin-right: 5px;
      }
    }
  }

  .cart {
    width: 90%;
    border: 1px solid #aaaaaa;
    margin-top: 25px;
    border-radius: 3px;
    padding: 10px 10px;
    max-height: 350px;
    height: fit-content;

    .header {
      border-bottom: 1px solid $gray;
      margin: 0;
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      padding-bottom: 3px;
      align-items: flex-end;

      h2 {
        display: inline-block;
        font-size: 1rem;
        font-weight: 400;
      }

      i {
        display: inline-block;
        font-size: 1.4rem;
        color: $secondary-color;
        margin-right: 10px;
      }
    }

    .body {
      max-height: 150px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .alert {
        margin: 10px;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
      }

    }

    .body::-webkit-scrollbar {
      display: none;
    }

    .cart-item {
      display: flex;
      border-bottom: 1px solid $gray;
      padding: 5px 0;

      .remove {
        flex: 1;

        i {
          color: $primary-color;
          cursor: pointer;
        }
      }

      .cart-body {
        flex: 10;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          p {
            padding: 0 3px;
            font-size: 1rem;
            font-weight: 600;
            width: 10%;
          }

          h2 {
            font-size: 0.85rem;
            font-weight: 500;
            width: 69%;
          }
        }

        .tier {
          display: flex;
          padding: 1px 0;

          .tier-title {
            font-size: 0.8rem;
            font-weight: 700;
            padding-right: 8px;
          }

          .tier-value {
            font-size: 0.8rem;
            padding: 0 0;
            width: 100%;
          }

          ul {
            list-style: none;
            margin: 0;
            li {
              font-size: 0.65rem;
            }
          }
        }

      }

      .price {
        color: $secondary-color;
        padding: 0 5px;
        font-size: 0.75rem;
        font-weight: 800;
        width: 21%;

        p {

        }
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px 0 0;

      .label {
        font-weight: 400;
      }

      p {
        font-weight: 800;
      }
    }

    .checkout-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      background: $secondary-color;
      margin: 5px 10px 0 10px;
      font-size: 1rem;
      font-weight: 600;
      color: white;

      span {
        border: none;
        font-size: 1rem;
      }
    }

  }
}

@media screen and (max-width: 1000px) {
  #product {
    .shop-side-cart {
      display: none;
    }

    .row {
      width: 100% !important;
    }

    .customize {
      padding: 0;
      .head {
        width: 95%;
        h1 {
          font-size: 2rem;
        }
      }

      .tiers {
        width: 95%;
      }
    }
  }
}


@media screen and (max-width: 515px) {
  #product {
    flex-direction: column;
    .column {
      .img {
        margin: auto auto;
      }

      .customize {
        margin-top: 0;
        .tiers {
          border-radius: 0;
        }
        .footer {
          flex-direction: column;
          width: 100%;
          text-align: center;
          overflow: hidden;

          .quantity {
            padding: 10px 0;

          }
        }

        .footer > * {
          flex: 1 1;
          width: 100%;
          padding: 15px 0;
          border-radius: 0;
          border-bottom: 1px solid $gray;
          justify-content: center;
        }
      }
    }
  }
}
