$secondary-color: #244632;
$secondary-dark-color: #1a3325;
$secondary-lighter-color: #2f5d43;
$primary-color: #dac292;
$primary-dark-color: #a2916e;
$primary-lighter-color: #efd5a2;

$primary-bg: #ffffff;
$secondary-bg: #4d4e53;

$success: #3f9c35;
$error: #cc0000;
$warning: #ec7a08;
$info: #00b9e4;
$active: #0088ce;

$gray: #aaaaaa;
$cloud: #f1f1f1;
$coal: #444444;
