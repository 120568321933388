button:focus {
  outline: none;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

select:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}
