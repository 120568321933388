#privacy-policy {
  padding: 25px;

  .column {
    display: flex;
    flex-direction: column;

    h1 {
      color: $secondary-color;
      font-size: 3rem;
      font-weight: 900;
    }

    h3 {
      color: $secondary-color;
      margin-top: 15px;
      font-size: 1rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      margin-bottom: 25px;
    }

    ul {
      margin-top: 0;
    }
  }
}


@media screen and (max-width: 760px) {
  #privacy-policy {
    padding: 5px;

    .column {
      padding: 0;
      text-align: justify;

      h1 {
        font-size: 2rem;
        margin-bottom: 5px;
      }
    }
  }
}
