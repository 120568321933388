.suggestions-section {
  margin-bottom: 50px;
  .suggestions-title {
    margin: 50px 0px 20px 16px;

    h1 {
      color: $secondary-color;
      font-size: 1.9rem;
      font-weight: 900;
    }
    span {
      color: $primary-color;
    }
  }
  .suggestions {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    .suggestions-title-span {
      color: $primary-color;
    }
    .suggestion {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      justify-content: center;
      align-items: center;
      position: relative;

      a {
      }
    }

    .img {
      width: 75%;
      justify-content: center;
      align-items: center;
      display: flex;
      img {
        width: 90%;
      }
    }

    .title {
      color: $secondary-color;
      font-size: 1.2rem;
      height: 50px;
      text-align: center;
      margin: 10px 0;
      font-weight: 900;
      text-transform: uppercase;
    }
  }
}
@media screen and (max-width: 768px) {
  .suggestions-section {
    .suggestions {
      flex-wrap: wrap;
      .suggestion {
        flex: 1 1 30%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .suggestions-section {
    .suggestions-title {
      h1 {
        font-size: 1.5rem;
      }
    }
    .suggestions {
      flex-wrap: wrap;
      .suggestion {
        flex: 1 1 50%;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .suggestions-section {
    .suggestions {
      flex-wrap: wrap;
      .suggestion {
        flex: 1 1 100%;
      }
    }
  }
}
