#checkout-response {
  display: flex;

  .column {
    display: flex;
    flex-direction: column;
  }

  .header {
    border-bottom: 2px solid $gray;
    padding: 5px 0;
    width: 100%;

    h3 {
      color: $secondary-color;
      font-size: 1rem;
      font-weight: 900;
    }
  }
  .order-summary {
    border: 1px solid $gray;
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 5px;

    .item {
      display: flex;
      border-bottom: 1px solid $gray;
      padding-bottom: 5px;
      color: $coal;
      flex-direction: column;

      .header {
        display: flex;
        color: $secondary-color;
        border-bottom: none;

        .title {
          width: 80%;
        }
        .price {
          width: 20%;
        }
      }

      .body {
        flex: 9;

        .title {
          color: $secondary-color;
          font-size: 1.1rem;
          margin-top: 5px;
        }

        .tier {
          padding: 1px 20px;
          font-size: 0.95rem;

          strong {
            margin-right: 3px;
          }
          ul {
            margin: 0;
            width: 100%;
            padding-left: 16px;
          }
        }
      }

      .price {
        flex: 1 1;
        display: flex;
        justify-content: center;
        font-weight: 700;
      }
    }


    .notes {
      color: $coal;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      border-bottom: 1px solid $gray;

      h5 {
        font-size: 1rem;
      }

      p {
        font-size: 1rem;
      }
    }

    .summary {
      display: flex;
      flex-direction: column;
      font-size: 1.1rem;
      color: $coal;

      .row {
        display: flex;
        justify-content: space-between;
        padding: 3px 0;

        .label {
          font-size: 0.85rem;
        }

        .value {
          font-size: 0.85rem;
          padding-right: 10px;
          font-weight: 500;
        }
      }

      .total {
        padding-right: 10px;
        font-weight: 500;
        color: $primary-color
      }
    }
  }


  .customer-summary {
    padding: 10px;
    border: 1px solid $gray;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 5px;

    .body {
      padding: 10px;
    }

    .footer {
      padding: 10px;


      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

      }
    }
  }

  .response {
    margin: 5px;
    .info {
      background: $info;
      color: $primary-font-color;
      font-weight: 800;
      padding: 10px;
      width: 90%;
      margin: 0 auto;
    }
    .response-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      .status {
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        flex: 1;

        .accepted {
          background: $success;
          color: $primary-font-color;
          font-weight: 800;
          padding: 10px;
          width: 90%;
          margin: 0 auto;
        }
        .rejected {
          background: $primary-lighter-color;
          color: $primary-font-color;
          font-weight: 800;
          padding: 10px;
          width: 90%;
          margin: 0 auto 20px auto;
        }
      }

      .method {
        color: $primary-font-color;
        background: $secondary-color;
        width: 50%;
        margin: 7px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 46px;
        cursor: pointer;
        border: none;
        font-size: 1.2rem;
        font-weight: 800;

        h3 {
          font-weight: 900;
        }
      }

      .discard {
        background: $primary-color;
      }

      .payments {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .note {
          font-size: 0.75rem;
          color: $primary-lighter-color;
          font-weight: 600;
          margin: 10px 0;
          text-align: center;
        }

        .paypal {}
        .worldpay {}
        .on-collection {}
        .on-delivery {}
        .with-card {}
        .card-title {
          font-size: 1rem;
          width: 50%;
          font-weight: 900;
        }
        .form-with-card {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .card-element {
            width: 100%;
            padding: 10px 0;
            border: 1px solid $cloud;
            margin: 10px 0;
            .StripeElement {
              padding: 0 10px;
            }
            input {
              border: 2px solid red;
            }
          }
          .card-button {
          }
        }
      }
    }
  }
}

@media screen and (max-width:1100px)  {
  #checkout-response {

    .booking-details {
      flex-direction: column;
    }
  }
}

@media screen and (max-width:760px)  {
  #checkout-response {
    flex-direction: column-reverse;
    .booking-details {
      flex-direction: column;
    }

    .response {
      margin: 5px 0;
      .info {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .loader-wrapper {
    height: 40vh;
  }
}
